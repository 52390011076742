import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Video from 'twilio-video';
import AppStateProvider from './state';
import Routes from './Routes';
import { MixpanelProvider } from 'providers/MixpanelContext';
import { ThemeProvider } from '@material-ui/styles';
import { AnimatePresence } from 'framer-motion';
import MuiTheme from './theme';
import { UserProvider } from 'providers/UserContext';
import { LayoutProvider } from 'providers/LayoutContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from '@sentry/react';

import './assets/base.scss';

const App = () => (
  <MixpanelProvider>
    <UserProvider>
      <BrowserRouter>
        <ThemeProvider theme={MuiTheme}>
          <LayoutProvider>
            <AnimatePresence>
              <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                limit={2}
                pauseOnHover
                key="toast"
                style={{
                  fontSize: '17px'
                }}
              />
              {Video.isSupported ? (
                <AppStateProvider>
                  <Routes />
                </AppStateProvider>
              ) : (
                <Routes />
              )}
            </AnimatePresence>
          </LayoutProvider>
        </ThemeProvider>
      </BrowserRouter>
    </UserProvider>
  </MixpanelProvider>
);

export default Sentry.withProfiler(App);
