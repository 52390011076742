import Axios from "../AxiosConfig";

const get = async (url, config = {}) => {
    try {
        const response = await Axios.get(url, config);
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, data: error.response };
    }
};

const post = async (url, body, headerProps = {}) => {
    try {
        const response = await Axios.post(url, body, { headers: headerProps });
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, data: error.response };
    }
};

const patch = async (url, body, isFormData = false) => {
    const headers = isFormData ? { 'Content-Type': 'multipart/form-data' } : {};
    try {
        const response = await Axios.patch(url, body, { headers });
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, data: error.response };
    }
};

const put = async (url, body, isFormData = false) => {
    const headers = isFormData ? { 'Content-Type': 'multipart/form-data' } : {};
    try {
        const response = await Axios.put(url, body, { headers });
        return response.data.error ? { success: false, data: response.data.error } : { success: true, data: response.data };
    } catch (error) {
        return { success: false, data: error.response };
    }
};

const del = async (url) => {
    try {
        const response = await Axios.delete(url);
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, data: error.response };
    }
};

const request = { get, post, patch, put, del };

export default request;
