import { toast as Toast } from "react-toastify";
import "./toast.css";

const defaultOptions = {
  position: "bottom-center",
  autoClose: 1000,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
};

const success = (title, options = {}) => {
  Toast.success(title, {
    ...defaultOptions,
    ...options,
    className: "toast-success",
  });
};

const warning = (title, options = {}) => {
  Toast.warning(title, {
    ...defaultOptions,
    ...options,
    className: "toast-warning",
  });
};

const error = (title, options = {}) => {
  Toast.error(title, {
    ...defaultOptions,
    ...options,
    className: "toast-error",
  });
};

const info = (title, options = {}) => {
  Toast(title, {
    ...defaultOptions,
    ...options,
    className: "toast-info",
  });
};

const toast = {
  success,
  warning,
  error,
  info,
};

export default toast;
