/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { emitCustomEvent } from 'react-custom-events';
import { get } from 'lodash';
import { jwtDecode } from 'jwt-decode';
import { EventSourcePolyfill } from 'event-source-polyfill';

import api, { BASE_URL } from '../api';
import { CircularProgress, Typography } from '@material-ui/core';
import { useMixpanel } from './MixpanelContext';
import toast from '../utils/toast';
import { clearStorage } from '../utils/helpers';
import { Tokens } from '../store';
import { useCustomEventListener } from 'react-custom-events';

export const UserContext = React.createContext();

let interval;
let sseAdmin;
let sseByod;
let sseRequests;

export const UserProvider = ({ children }) => {
    const [tokens] = useRecoilState(Tokens);
    const mixpanel = useMixpanel();
    const [user, setUser] = React.useState();
    const [userType, setUserType] = React.useState();
    const [org, setOrg] = React.useState();
    const [dbLoading, setDBLoading] = React.useState(false);
    const [UIBlock, setUIBlock] = React.useState();
    const [refreshSSE, setRefreshSSE] = React.useState('init');
    const [allShActions, setAllShActions] = React.useState([]);
    const [appType, setAppType] = React.useState('unknown');


    const getUserInfo = useCallback(async () => {
        if (!tokens?.IdToken || !mixpanel) setDBLoading(true);
        setUIBlock(true);
        try {
            const user = jwtDecode(tokens.IdToken); // decode your token here
            sessionStorage.setItem('user', JSON.stringify(user));
            // if (process.env.REACT_APP_APIMODE === 'local') console.log(`USER: ${JSON.stringify(user)}`);

            let type;
            switch (user['custom:role']) {
                case 'superAdmin':
                case 'admin':
                    type = 'admin';
                    break;
                case 'bay':
                    type = 'bay';
                    break;
                case 'partner':
                    type = 'partner';
                    break;
                case 'charge':
                case 'nurse':
                    type = 'nurse';
                    break;
                default:
                    //const userRole = user['custom:role'];
                    console.log(`unknown user role: ${user['custom:role']}`);
                    break;
            }

            setUserType(type);
            // console.log(`--- USERTYPE: ${user['custom:role']}  ${type}`);
            let res = await api.getUser(user['custom:role'], user['cognito:username']);

            if (res === null) {
                clearStorage();
                toast.error('Your accounts do not have access to this portal.');
                setUser(null);
                setDBLoading(false);
                setUIBlock(false);
                window.location = '/auth/login';
                return;
            }

            //  cna 20240219 if admin user, preload smarthubAction table if not loaded 
            if (type === 'admin' && allShActions.length === 0) {
                const x = await api.getShActions();
                setAllShActions(x);
                //console.log(`shAction-x`, x);
            }

            if (res !== 'auth/user-not-found') {
                mixpanel.identifyUser(res.uid, {
                    $email: res.email,
                    $created: res.created_at,
                    USER_ID: res._id,
                    USER_ROLE: res.role,
                    FIREBASE_UID: res.uid,
                    ORGANIZATION: get(res, 'organisation._id'),
                    ORGANIZATION_NAME: get(res, 'organisation.name')
                });

                if (type === 'admin') {
                    // console.log(`AdminSignon`, res.organisation);
                    setAppType('adminpanel');
                    setUser(res);

                    if (res && res.organisation && res.organisation.registration_code === 'BIDMC') res.organisation.demo = true;
                    if (res.organisation) {
                        setOrg(res.organisation);
                    } else {
                        const orgres = await api.getOrganizations();
                        if (orgres.length > 0) {
                            setOrg(orgres[0]);
                        } else {
                            toast.error('There is no org info for this account');
                            clearStorage();
                            setUser(null);
                            setDBLoading(false);
                            setUIBlock(false);
                            window.location = '/auth/login';
                        }
                    }

                } else if (type === 'partner' || type === 'bay') {
                    setUser(user);
                    document.title = "ComCierge BYOD";
                    setAppType('byod');
                } else if (type === 'nurse') {
                    setAppType('adminpanel');
                    setUser(res);
                    if (res.organisation) {
                        setOrg(res.organisation);
                    }
                } else {
                    setUser(null);
                    toast.error('This user account does *NOT* have access to this portal');
                }
            } else {
                setUser(null);
                toast.error('This user account does not have access to this portal!');
            }
        } catch (e) {
            toast.error('This user account does not have access to this portal');
        }

        if (dbLoading) setDBLoading(false);
        setUIBlock(false);
    }, [mixpanel, tokens, refreshSSE]);



    function doSseByod() {

        if (tokens) {

            // cna 20240502 only connect if not connected 
            if (sseByod && (sseByod.readyState == EventSource.OPEN || sseByod.readyState == EventSource.CONNECTING)) {
                // DO NOTHING
            }
            else {
                console.log(`SSE-BYOD subscribe`);

                if (sseByod) sseByod.close();

                // cna 20240502 60000 ms till consider connection issue, backend is sending heartbeat every 55 seconds
                sseByod = new EventSourcePolyfill(`${BASE_URL()}/events/byod`, {
                    headers: { Authorization: 'Bearer ' + tokens.AccessToken, 'x-byod': sessionStorage.getItem('xByod') },
                    heartbeatTimeout: 60000,
                    withCredentials: true
                });

                sseByod.onmessage = (e) => processSseEventsByod(e);
                sseByod.onopen = (e) => { console.log(`sseByod Open`); }

                sseByod.onerror = (e) => {
                    console.log('sseByod onerror');
                    if (e?.status === 401) {
                        const isMobile = sessionStorage.getItem('mobile');
                        const searchParams = sessionStorage.getItem('searchParams');
                        clearStorage();
                        setUser(null);
                        setDBLoading(false);
                        setUIBlock(false);
                        if (isMobile === '1') {
                            window.location = `/auth/login${searchParams}`;
                        } else {
                            window.location = '/auth/login';
                        }
                    }
                };
            }
        }
    }


    /* 
        cna 20240502
        eventSourcePolyFill onerror - 
           * onerror repeatedly errors starting at ~3 seconds then backing off to every ~20 seconds in ~12 retries
           * connection is automatically retried (got an onopen event after restarting backend)
           * sseAdmin.readyState = 0 CLOSED, 1 = OPEN
           * heartbeatTimeout is time to wait for data until connection considered broken in ms 
    */
    function doSseAdmin() {

        if (tokens) {

            // cna 20240502 only connect if not connected 
            if (sseAdmin && (sseAdmin.readyState == EventSource.OPEN || sseAdmin.readyState == EventSource.CONNECTING)) {
                // DO NOTHING
            }
            else {
                console.log(`SSE-ADM subscribe`);
                if (sseAdmin) sseAdmin.close();

                // cna 20240502 60000 ms till consider connection issue, backend is sending heartbeat every 55 seconds
                sseAdmin = new EventSourcePolyfill(`${BASE_URL()}/events/subscribe`, {
                    headers: { Authorization: 'Bearer ' + tokens.AccessToken },
                    heartbeatTimeout: 60000,
                    withCredentials: true
                });

                sseAdmin.onopen = (e) => { console.log(`sseAdmin Open`); }
                sseAdmin.onmessage = (e) => processSseEventsAdmin(e);

                sseAdmin.onerror = (e) => {
                    console.log('SSE-ADM onerror');

                    if (e?.status === 401) {
                        clearStorage();
                        setUser(null);
                        setDBLoading(false);
                        setUIBlock(false);
                        window.location = '/auth/login';
                    }
                };
            }
        }
        return () => { };
    }

    /*******************************************************************************
    * cna 20240214 testing for requests(ACE BG)
    * leave this here to test ACE BG SSE requests
    *******************************************************************************/
    /*
    function doSseRequests() {
    
        if (tokens) {

            // cna 20240502 only connect if not connected 
            if (sseRequests && (sseRequests.readyState == EventSource.OPEN || sseRequests.readyState == EventSource.CONNECTING)) {
                // DO NOTHING
            }
            else {
                console.log(`sseRequests subscribe`);
                if (sseRequests) sseRequests.close();

                // cna 20240502 60000 ms till consider connection issue, backend is sending heartbeat every 55 seconds
                sseRequests = new EventSourcePolyfill(`${BASE_URL()}/events/requests`, {
                    headers: { Authorization: 'Bearer ' + tokens.AccessToken },
                    heartbeatTimeout: 60000,
                    withCredentials: true
                });

                sseRequests.onopen = (e) => { console.log(`sseRequests Open`); }
                sseRequests.onmessage = (e) => processSseEventsAdmin(e);

                sseRequests.onerror = (e) => {
                    console.log('sseRequests onerror');

                    if (e?.status === 401) {
                        clearStorage();
                        setUser(null);
                        setDBLoading(false);
                        setUIBlock(false);
                        window.location = '/auth/login';
                    }
                };
            }
        }
        return () => { };
    }
    */


    /*
     * cna 20230606 this is where we process requests events from SSE 
     * written for testing only to emulate ACE BG setup
     */
    function processSseEventsPatientRequests(data) {
        try {
            const _data = JSON.parse(data.data);
            const type = _data?.type ?? undefined;
            if (!type || type == 'hb') return;
            console.log(`SSE-REQ: ${JSON.stringify(_data)}`);

            if (type === 'req_cancelled' ||
                type === 'req_closed' ||
                type === 'req_created' ||
                type === 'req_created'
            ) {
                emitCustomEvent('requestEvent', _data);
            } else {
                emitCustomEvent('other-event', _data);
            }
        } catch (error) {
            console.log('~~~ Error processEventsFromServer parse data: ', error);
        }
    }

    useCustomEventListener('requestEvent', (data) => {
        // console.log(`SSE-REQ: ${JSON.stringify(data)}`);
    })


    /*
     * cna 20230606 this is where we process admin events from SSE for ADMIN panel
     */
    function processSseEventsAdmin(data) {
        try {
            const _data = JSON.parse(data.data);
            const type = _data?.type ?? undefined;
            // if (type == 'hb') { console.log('hb'); return; }
            if (!type || type == 'hb') return;
            console.log(`SSEAdm: ${JSON.stringify(_data)}`);


            if (type === 'alert_updated' ||
                type === 'alert_removed' ||
                type === 'bay_updated' ||
                type === 'room_updated'
            ) {
                emitCustomEvent('room-manager-event', _data);
            } else if (type === 'req_cancelled' ||
                type === 'req_closed' ||
                type === 'req_created' ||
                type === 'req_created'
            ) {
                emitCustomEvent('active-requests-event', _data);
            } else if (type === 'nurse_assigned' ||
                type === 'nurse_unassigned') {
                emitCustomEvent('assignment-manager-event', _data);
            } else {
                emitCustomEvent('other-event', _data);
            }
        } catch (error) {
            console.log('~~~ Error processEventsFromServer parse data: ', error);
        }
    }


    // ju 20231214 this is where we process BYOD events from SSE
    // NOTE: BYOD only receives events associated with the BAY for the BYOD(not broadcast)
    function processSseEventsByod(data) {
        try {
            const _data = JSON.parse(data.data);
            const type = _data?.type ?? undefined;
            // if (type == 'hb') { console.log('hb'); return; }
            if (!type || type == 'hb') return;
            console.log(`SSEByod: ${JSON.stringify(_data)}`);

            if (
                type === 'req_cancelled' ||
                type === 'req_closed' ||
                type === 'req_created' ||
                type === 'nurse_assigned' ||
                type === 'nurse_unassigned'
            ) {
                emitCustomEvent('byod-requests', _data);
            } else if (type === 'siemens_updated') {
                emitCustomEvent('siemens_updated', _data)
            } else {
                console.log(`ERROR unknown BYOD-SSE notification type: ${_data.type}`);
            }
        } catch (error) {
            console.log('~~~ Error processEventsFromServer parse data: ', error);
        }
    }

    // cna 20240103 this is where we refresh the SSE connection for admin panel
    React.useEffect(() => {
        if (appType == 'byod') {
            doSseByod();
        } else if (appType == 'adminpanel') {

            doSseAdmin();
        }
    }, [appType, tokens]);


    React.useEffect(() => {
        if (tokens && getUserInfo) {
            getUserInfo();
        }
    }, [tokens, getUserInfo]);


    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                dbLoading,
                setUIBlock,
                UIBlock,
                org,
                setOrg,
                userType,
                allShActions,
            }}>
            {children}
            {UIBlock && (
                <div
                    style={{
                        width: '100vw',
                        height: '100vh',
                        position: 'fixed',
                        zIndex: 2000,
                        top: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'rgba(150, 150, 150, 0.4)'
                    }}>
                    <div
                        style={{
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '1.5rem',
                            borderRadius: 8,
                            background: 'white',
                            minHeight: 200,
                            minWidth: 200
                        }}>
                        <CircularProgress variant="indeterminate" />
                        <Typography style={{ margin: '1.5rem 0 0.25rem 0' }} variant="h5">
                            {UIBlock.title}
                        </Typography>
                        <Typography variant="body1">{UIBlock.message}</Typography>
                    </div>
                </div>
            )}
        </UserContext.Provider>
    );
};

export function useUser() {
    let context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
}
