import { atom } from 'recoil';
import { getToken } from '../AxiosConfig';

export const Tokens = atom({
  key: 'Tokens',
  default: getToken(),
});
export const Orgs = atom({
  key: 'Orgs',
  default: [],
});
export const TmpInfo = atom({
  key: 'TmpInfo',
  default: null,
});
