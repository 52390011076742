export const DEFAULT_VIDEO_CONSTRAINTS = {
    width: 1280,
    height: 720,
    frameRate: 24,
};

// These are used to store the selected media devices in sessionStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

// This is used to store the current background settings in sessionStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'TwilioVideoApp-selectedBackgroundSettings';

// cna 20240906 only R5 is 115200 - all others are 19200 (even R5-TV19200)
export const nurseCallSystemTypes = [
    { value: 'CRITICAL-ALERT', label: 'CRITICAL-ALERT' },
    { value: 'HILLROM', label: 'HILLROM' },
    { value: 'R4', label: 'R4' },
    { value: 'R5', label: 'R5' },
    { value: 'R5-TV19200', label: 'R5-TV19200' },
    { value: 'WESTCOM', label: 'WESTCOM' },
];


export const smarthubTvOptions = [
    { value: 'ALLENTEK_LG', label: 'ALLENTEK_LG' },
    { value: 'ALLENTEK_LGPRO', label: 'ALLENTEK_LGPRO' },
    { value: 'ALLENTEK_SAMSUNG', label: 'ALLENTEK_SAMSUNG' },
    { value: 'ANA_LGPRO', label: 'ANA_LGPRO' },
    { value: 'ANA_PDI', label: 'ANA_PDI' },
    { value: 'ANA_PHILIPS', label: 'ANA_PHILIPS' },
    { value: 'ANA_RCA', label: 'ANA_RCA' },
    { value: 'ANA_SAMSUNG', label: 'ANA_SAMSUNG' },
    { value: 'ANA_STANDARD', label: 'ANA_STANDARD' },
    { value: 'GETWELL_IMX', label: 'GETWELL_IMX' },
    { value: 'GETWELL_LGPRO', label: 'GETWELL_LGPRO' },
    { value: 'SKYLIGHT', label: 'SKYLIGHT' },
    { value: 'SONIFI_LGPRO', label: 'SONIFI_LGPRO' },
];
