import { useRecoilState } from "recoil";
import { useUser } from 'providers/UserContext';
import React, { lazy, Suspense } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import SuspenseLoading from 'shared/SuspenseLoading';
import { Tokens } from "./store";

const NotFound = lazy(() => import('./pages/NotFound'));
const MobileHomePage = lazy(() => import('./pages/App/Mobile/HomePage'));
const MobileDevPage = lazy(() => import('./pages/App/Mobile/Dev'));
const MobileDev2Page = lazy(() => import('./pages/App/Mobile/Dev2'));
const DashboardLayout = lazy(() => import('./layouts/DashboardLayout'));
const AuthLayout = lazy(() => import('./layouts/AuthLayout'));

export const PrivateRoute = ({ component, authenticated, ...rest }) => {
  const { dbLoading } = useUser();
  const [tokens] = useRecoilState(Tokens);
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {dbLoading ? (
            <div />
          ) : tokens ? (
            <>{React.createElement(component, props)}</>
          ) : (
            <Redirect
              to={{
                pathname: '/auth',
                state: {
                  from: props.location
                }
              }}
            />
          )}
        </>
      )}
    />
  );
};

export const PublicRoute = ({ component, ...rest }) => {
  const { dbLoading } = useUser();
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {dbLoading ? (
            <div />
          ) : (
            React.createElement(component, props)
          )}
        </>
      )}
    />
  );
};

export default function Routes() {
  return (
    <Suspense fallback={<SuspenseLoading />}>
      <Switch>
        <Redirect exact from="/" to="/app" />
        <Route exact path="/app/mobile/home" component={MobileHomePage} />
        <Route exact path="/app/mobile/dev" component={MobileDevPage} />
        <Route exact path="/app/mobile/dev2" component={MobileDev2Page} />
        <PrivateRoute path="/app" component={DashboardLayout} />
        <PublicRoute path="/auth" component={AuthLayout} />
        <Route path='*' component={NotFound}/>
      </Switch>
    </Suspense>
  );
}
