/* eslint-disable no-undef */
/* eslint-disable no-empty */
import axios from 'axios';
import { currentUser, SUPER_BASE_URL } from './api';
import { clearStorage } from './utils/helpers';

const instance = axios.create({
    headers: { 'Content-Type': 'application/json', },
    withCredentials: true,
});

instance.interceptors.request.use(
    (config) => {
        const token = getToken()?.AccessToken;
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        if (currentUser().superadmin) {
            config.headers['superadmin'] = true;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        // console.log(originalConfig, 'originalConfig')
        // cna 20240206 use regex so we dont have to worry about v#
        if (/\/api\/v\d{1,2}\/bays\/me/.test(originalConfig.url) && err.response) {
            if (err.response.status === 401 && !originalConfig._retry) {
                clearStorage();
                const searchParams = sessionStorage.getItem('searchParams');
                if (searchParams) window.location = `/auth/login${searchParams}&error=1`;
                return instance(originalConfig);
            }

        }
        // cna 20240206 use regex so we dont have to worry about v#
        if (!/\/api\/v\d{1,2}\/auth\/login/.test(originalConfig.url) && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    let headers = { 'Content-Type': 'application/json', };
                    const token = getToken().AccessToken;
                    if (token) headers['Authorization'] = 'Bearer ' + token;
                    if (currentUser().superadmin) headers['superadmin'] = true;
                    const rs = await instance.post(`${SUPER_BASE_URL()}/auth/refresh?superadmin=${currentUser().superadmin}`,
                        { refresh_token: getToken().RefreshToken, }, { headers, },);
                    sessionStorage.setItem('token', JSON.stringify(rs.data));
                    return instance(originalConfig);
                } catch (_error) {
                    const secondConfig = _error.config;
                    console.log(secondConfig, 'secondconfig');
                    // cna 20240206 use regex so we dont have to worry about v#
                    if (/\/api\/v\d{1,2}\/auth\/refresh/.test(secondConfig.url)) {
                        clearStorage();
                        const searchParams = sessionStorage.getItem('searchParams');
                        if (searchParams) {
                            window.location = `/auth/login${searchParams}&error=1`;
                        } else {
                            window.location = '/auth/login';
                        }

                    } else {
                        const searchParams = sessionStorage.getItem('searchParams');
                        if (searchParams) {
                            window.location = `/auth/login${searchParams}&error=1`;
                        } else {
                            window.location = '/auth/login';
                        }
                    }
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    },
);
export default instance;

export const getToken = () => {
    let token = sessionStorage.getItem('token');
    if (token) {
        try {
            return JSON.parse(token);
        } catch (e) {
            return null;
        }

    } else {
        return null;
    }
};
